<template>
  <div class="OrderPage">

    <!-- Order Modal-->
    <OrderModal
      v-bind:printOrder="printOrder"
      v-bind:selectedOrder="order">
    </OrderModal>

    <!-- Order Form -->
    <form @submit.prevent="saveChanges">
      <va-card stripe="secondary">

        <!-- Controls -->
        <div class="row">
          <div class="flex xs12 md7">
            <h1 class="display--1 text--capitalize">{{ mode }} Order Form</h1>
          </div>

          <!-- Go Back -->
          <div class="flex xs12 md2 text-right">
            <va-button
              outline
              medium
              color="info"
              icon="fa fa-arrow-left"
              type="button"
              v-on:click="goBack">
              Back
            </va-button>
          </div>

          <!-- Print Order -->
          <div class="flex xs12 text-right md3">
            <va-button
              medium
              color="secondary"
              icon="fa fa-print"
              type="button"
              v-on:click="launchPrintModal">
              Print Order
            </va-button>
          </div>
        </div>

        <!-- Row 1 -->
        <div class="row">
          <!-- Order id -->
          <div class="flex md7 xs12">
            <va-input
              label="Order ID"
              v-model="order.orderID"
              v-bind:disabled="true"
              placeholder="Type Order ID here"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="vuestic-iconset vuestic-iconset-components"
              />
            </va-input>
          </div>

          <!-- Customer id -->
          <div class="flex md5 xs12">
            <va-input
              label="Customer ID"
              v-model="order.customerID"
              v-bind:disabled="true"
              placeholder="Type Customer ID here"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-id-card"
              />
            </va-input>
          </div>
        </div>

        <!-- Row 2 -->
        <div class="row">
          <!-- Order Date -->
          <div class="flex md4 xs12">
            <va-input
              label="Order Date"
              v-model="order.date"
              placeholder="Type Order Date here">
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-calendar"
              />
            </va-input>
          </div>

          <!-- Order Shipping -->
          <div class="flex md4 xs12">
            <va-input
              label="Order Shipping"
              v-bind:disabled="true"
              v-model="order.shipping"
              placeholder="Type Order Shipping here"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-truck"
              />
            </va-input>
          </div>

          <!-- Order Status -->
          <div class="flex md4 xs12">

            <va-select
              label="Order Status"
              v-model="order.status"
              :options="statusOptions"
            />
          </div>
        </div>

        <!-- Row 3 -->
        <div class="row">
          <!-- Order Tracking -->
          <div class="flex md8 xs12">
            <va-input
              label="Order Tracking Number"
              v-model="order.tracking"
              placeholder="Type Tracking Number here">
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-globe"
              />
            </va-input>
          </div>

          <!-- Print Order -->
          <div class="flex xs12 text-right md4">
            <va-button
              medium
              color="success"
              icon="fa fa-globe"
              type="button"
              v-bind:disabled="loading"
              v-on:click="sendTracking">

              <!-- Button Content -->
              <div v-if="loading">
                Sending Tracking Info...
              </div>

              <div v-else>
                Send Tracking Info
              </div>
            </va-button>
          </div>
        </div>
      </va-card>

      <va-card class="my-3" stripe="info">
        <h1>Ordered Items</h1>

        <va-data-table
          :fields="fields"
          :data="order.orderedItems"
          :per-page="100"
          hoverable
          no-data-label="Nothing to show right now :("
        >

          <template slot="price" slot-scope="props">
            <p>€{{ parseFloat(props.rowData.price).toFixed(2) }}</p>
          </template>

        </va-data-table>
      </va-card>

      <!-- Order Value Totals -->
      <va-card class="my-3" stripe="warning">

        <h1>Order Taxs and Totals</h1>
        <div class="row mt-2">

          <!-- Order SubTotal -->
          <div class="flex md4 xs12">
            <va-input
              label="Order SubTotal"
              v-bind:disabled="true"
              v-model="subTotal"
              placeholder="Type Order SubTotal here">
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-calculator"
              />
            </va-input>
          </div>

          <!-- Order VAT -->
          <div class="flex md4 xs12">
            <va-input
              label="Order VAT"
              v-model="vat"
              v-bind:disabled="true"
              placeholder="Type Order VAT here"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-balance-scale"
              />
            </va-input>
          </div>

          <!-- Order Total -->
          <div class="flex md4 xs12">
            <va-input
              label="Order Total"
              v-model="total"
              v-bind:disabled="true"
              placeholder="Type Order Total here"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-euro"
              />
            </va-input>
          </div>
        </div>

        <!-- Controls Bottom -->
        <div class="row">

          <!-- Go Back -->
          <div class="flex xs12 md9">
            <va-button
              outline
              medium
              color="info"
              type="button"
              icon="fa fa-arrow-left"
              v-on:click="goBack">
              Back
            </va-button>
          </div>

          <!-- Save form -->
          <div class="flex xs12 md3">
            <va-button
              medium
              color="info"
              type="submit"
              icon="fa fa-save"
            >
              Save Order
            </va-button>
          </div>
        </div>
      </va-card>

    </form>
  </div>
</template>

<script>
import axios from 'axios'
import OrderModal from '@/components_tc/orders/OrderModal.vue'
import { ordersCollection } from '@/firebaseConfig.js'

export default {
  name: 'OrderPage',
  props: ['order', 'mode'],
  data () {
    return {
      vat: '',
      total: '',
      subTotal: '',
      loading: false,
      printOrder: false,
      statusOptions: ['Dispatched', 'Paid', 'Refunded', 'Unpaid'],
    }
  },
  components: {
    OrderModal,
  },
  computed: {
    fields () {
      return [{
        name: 'name',
        title: this.$t('Product Name'),
        sortField: 'name',
        width: '50%',
      }, {
        name: 'description',
        title: this.$t('Product Option'),
        sortField: 'description',
        width: '30%',
      }, {
        name: 'quantity',
        title: this.$t('Quantity'),
        sortField: 'quantity',
        width: '10%',
      }, {
        name: '__slot:price',
        title: this.$t('Order Price'),
        sortField: 'price',
        width: '10%',
      }]
    },
  },
  methods: {
    goBack: function () {
      this.$emit('goBack')
    },

    launchPrintModal: function () {
      if (this.printOrder) {
        this.printOrder = false
      }
      this.printOrder = true
    },

    toDecimalplace: function () {
      this.vat = this.order.vat.toFixed(2)
      this.total = this.order.total.toFixed(2)
      this.subTotal = this.order.subTotal.toFixed(2)
    },

    saveChanges: function () {
      const _this = this
      ordersCollection
        .doc(this.order.orderID)
        .set(this.order)
        .then(() => {
          _this.loading = false
          _this.$emit('saveOrder')
          _this.goBack()
        })
    },

    sendTracking: function () {
      if (this.order.tracking) {
        this.loading = true
        axios
          .post(
            'https://us-central1-turtlecreek-2019.cloudfunctions.net/sendTrackingNumber',
            {
              order: this.order,
            },
          )
          .then(() => {
            this.order.status = 'Dispatched'
            this.saveChanges()
          })
          .catch(err => {
            alert(err.message)
            this.loading = false
          })
      }
    },
  },
  mounted () {
    this.toDecimalplace()
  },
}
</script>
