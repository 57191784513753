<template>
  <va-modal
    v-model="printOrder"
    :hideDefaultActions="true"
    size="large"
    maxWidth="100%"
    :fixedLayout="true"
  >
    <div class="row mb-4">
      <div class="flex xs12 md6">
        <h1 class="display-1">Order #{{ selectedOrder.orderID }}</h1>
      </div>
      <div class="flex xs12 md3 text-left">
        <p>{{ selectedOrder.date }}</p>
      </div>
      <div class="flex xs12 md3">
        <va-button
          color="secondary"
          v-on:click="saveReceipt"
          icon="fa fa-print"
        >
          Print
        </va-button>
      </div>
    </div>
    <div class="flex xs12 md12">
      <div class="flex xs12 sm12">

        <va-card>
          <template slot="header">
            <va-icon name="fa fa-check-circle mr-3" color="secondary"/>
            <h5 class="mt-0 mb-0 display-5">{{ selectedOrder.status }}
            </h5>
            <p><span class="text--secondary ml-5">by {{ selectedOrder.customerID }} </span></p>
          </template>

          <!-- Order items list -->
          <div v-for="order in selectedOrder.orderedItems" :key="order.orderID">
            <div class="row text-right">
              <div class="flex xs6 py-2 text-left">
                <strong>{{ order.name }}</strong>
                <p class="text--secondary">{{ order.description }}</p>
              </div>
              <p class="flex xs3 py-2">x{{ order.quantity }}</p>
              <p class="flex xs3 py-2">€{{ order.price }}{{ order.currency }}</p>
            </div>
          </div>

          <!-- Order tallys list -->
          <div class="text-right">
            <p>Subtotal: €{{ selectedOrder.subTotal }}</p>
            <p>Value Added Tax(VAT): €{{ selectedOrder.vat }}</p>
            <p>Shipping Cost: €{{ selectedOrder.shipping }}</p>

            <p>________________</p>
            <p>Order Total €{{ selectedOrder.total }}</p>
          </div>
        </va-card>
      </div>
    </div>
  </va-modal>
</template>

<script>
export default {
  name: 'orders',
  props: ['printOrder', 'selectedOrder'],
  methods: {
    saveReceipt () {
      window.print()
    },
  },
}

</script>
