<template>
  <div class="orders">
    <!-- Header -->
    <ObjectPageHeader
      v-bind:objectName="objectName"
      v-on:refreshData="refreshData($event)">
    </ObjectPageHeader>

    <!-- Info Tiles -->
    <div class="row">
      <div
        class="flex xs12 md3"
        v-for="(info, idx) in infoTiles"
        :key="idx"
      >
        <va-card class="mb-4" :color="info.color">
          <p class="display-2 mb-0" style="color: white;">{{ info.value }}</p>
          <p>{{info.text}}</p>
        </va-card>
      </div>
    </div>

    <!-- Data table -->
    <va-card v-if="!viewOrder" title="All Orders">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="Search by Order No. or Customer ID"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>

      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        hoverable
        no-data-label="Nothing to show right now :("
        :loading="loading"
      >

        <template slot="status" slot-scope="props">
          <va-badge :color="getColour(props.rowData)">
            {{ props.rowData.status }}
          </va-badge>
        </template>

        <template slot="actions" slot-scope="props">
          <va-popover :message="`View Order ${props.rowData.orderID}`" placement="left">
            <va-button flat small color="dark" icon="fa fa-eye" v-on:click="showOrder(props.rowData)"/>
          </va-popover>

          <va-popover :message="`Mark Order ${props.rowData.orderID} as Paid`" placement="left">
            <va-button flat small color="info" icon="fa fa-envelope" v-on:click="sendOrderEmail(props.rowData)"/>
          </va-popover>

          <va-popover :message="`${$t('tables.delete')} Order ${props.rowData.orderID}`" placement="left">
            <va-button flat small color="danger" icon="fa fa-trash" v-on:click="deleteOrder(props.rowData, props.rowIndex)"/>
          </va-popover>
        </template>

      </va-data-table>
    </va-card>

    <OrderPage v-else
      v-bind:mode="mode"
      v-bind:order="selectedOrder"
      v-on:saveOrder="saveOrder()"
      v-on:goBack="goBack()">
    </OrderPage>

  </div>

</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
import { ordersCollection, customerCollection } from '@/firebaseConfig.js'

import OrderPage from '@/components_tc/orders/OrderPage.vue'
import ObjectPageHeader from '@/components_tc/utilities/ObjectPageHeader.vue'

export default {
  name: 'orders',
  data () {
    return {
      term: null,
      orders: [],
      selectedOrder: {},
      perPage: '10',
      loading: false,
      viewOrder: false,
      objectName: '',
      perPageOptions: ['10', '25', '50', '100'],
      modals: {
        showDeleteModal: false,
      },
      infoTiles: [{
        color: 'secondary',
        value: '0',
        text: 'New Orders',
        icon: '',
      }, {
        color: 'info',
        value: '0',
        text: 'Total Orders ',
        icon: '',
      }, {
        color: 'warning',
        value: '0',
        text: 'Unique Customers',
        icon: '',
      }, {
        color: 'success',
        value: '€',
        text: 'Total Sales',
        icon: '',
      }],

    }
  },
  computed: {
    Orders: function () {
      return this.$store.state.orders
    },
    NewOrders: function () {
      return this.$store.state.orders.newOrders
    },
    TotalOrders: function () {
      return this.$store.state.orders.totalOrders
    },
    UniqueCustomers: function () {
      return this.$store.state.orders.uniqueCustomers
    },
    TotalRev: function () {
      return this.$store.state.orders.totalRev
    },
    fields () {
      return [{
        name: 'orderID',
        title: this.$t('Order id'),
        sortField: 'orderID',
        width: '20%',
      }, {
        name: 'date',
        title: this.$t('Order Date'),
        sortField: 'date',
        width: '15%',
      }, {
        name: 'customerID',
        title: this.$t('Customer ID'),
        sortField: 'customerID',
        width: '20%',
      }, {
        name: 'total',
        title: this.$t('Order Amount'),
        sortField: 'total',
        width: '15%',
      }, {
        name: '__slot:status',
        title: this.$t('tables.headings.status'),
        sortField: 'status',
        width: '10%',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.orders
      }

      return this.orders.filter(item => {
        return item.orderID.toLowerCase().startsWith(this.term.toLowerCase()) || item.customerID.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  components: {
    ObjectPageHeader,
    OrderPage,
  },
  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),

    refreshData: function (event) {
      this.getOrderData(event)
    },

    goBack: function () {
      this.viewOrder = false
    },

    showOrder: function (order) {
      this.mode = 'edit'
      this.selectedOrder = order
      this.viewOrder = true
    },

    saveOrder () {
      this.showToast(
        'Order Updated',
        {
          icon: 'fa-check-circle',
          position: 'bottom-right',
          duration: 2500,
          fullWidth: false,
        },
      )
    },

    getColour: function (statusData) {
      switch (statusData.status.toLowerCase()) {
        case 'paid':
          return 'info'
        case 'refunded':
          return 'warning'
        case 'dispatched':
          return 'success'
        case 'unpaid':
          return 'danger'
        default:
          return 'danger'
      }
    },

    sendOrderEmail: function (order) {
      const _this = this
      this.loading = true

      customerCollection
        .doc(order.customerID)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            // Make request to send emails again
            var customer = doc.data()
            _this.sendRequest(order, customer)
          } else {
            _this.showToast(
              'Cannot resend email. Customer data has been removed.',
              {
                icon: 'fa-check-circle',
                position: 'bottom-right',
                duration: 2500,
                fullWidth: false,
              },
            )
            _this.loading = false
          }
        })
        .catch(err => {
          alert(err.message)
          _this.loading = false
        })
    },

    sendRequest: function (order, customer) {
      axios
        .post(
          'https://us-central1-turtlecreek-2019.cloudfunctions.net/sendOrderMailNow',
          {
            customer: customer,
            order: order,
          },
        )
        .then(() => {
          this.updateOrderstatus(order)
        })
        .catch(err => {
          alert(err.message)
          this.loading = false
        })
    },

    updateOrderstatus: function (order) {
      const _this = this
      ordersCollection
        .doc(order.orderID)
        .update({
          status: 'Paid',
        })
        .then(function () {
          order.status = 'Paid'
          _this.loading = false
          _this.showToast(
            'Order Updated and Customer Email Sent',
            {
              icon: 'fa-check-circle',
              position: 'bottom-right',
              duration: 2500,
              fullWidth: false,
            },
          )
        })
        .catch(err => {
          alert(err.message)
          _this.loading = false
        })
    },

    deleteOrder: function (orderData, orderIndex) {
      const _this = this
      this.loading = true

      ordersCollection
        .doc(orderData.orderID)
        .delete()
        .then(function () {
          _this.deleteFromTable(orderData, orderIndex)
          _this.showToast(
            'Order Deleted',
            {
              icon: 'fa-trash',
              position: 'bottom-right',
              duration: 2500,
              fullWidth: false,
            },
          )
        })
        .catch(err => {
          alert(err.message)
          _this.loading = false
        })
    },

    deleteFromTable: function (orderData, orderIndex) {
      let realIndex
      const orderIndexRev = (this.orders.length - 1) - orderIndex

      if (orderData.orderID === this.orders[orderIndex].orderID) {
        realIndex = orderIndex
      } else if (orderData.orderID === this.orders[orderIndexRev].orderID) {
        realIndex = orderIndexRev
      }

      this.orders.splice(realIndex, 1)
      this.$store.commit('setOrders', this.orders)
      this.loading = false
    },

    getOrderData: function (event) {
      var _this = this
      this.loading = true
      if (this.Orders.ordersData === undefined || this.Orders.ordersData.length === 0 || event != null) {
        let newOrders = 0
        let totalRevenue = 0
        let uniqueCustomers = 0
        const seenCustomers = {}
        const today = this.getToday()

        ordersCollection
          .get()
          .then(function (dataSnapshot) {
            var jsonOrders = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()
              itemData.status = (itemData.status === undefined) ? 'Paid' : itemData.status

              newOrders += (itemData.date === today) ? 1 : 0
              totalRevenue += (itemData.total) ? parseFloat(itemData.total) : 0
              if (!seenCustomers[itemData.customerID]) {
                uniqueCustomers += 1
                seenCustomers[itemData.customerID] = true
              }

              jsonOrders.push(itemData)
            })
            jsonOrders = jsonOrders.slice(0, jsonOrders.length - 1).sort((a, b) =>
              parseFloat(a.orderID.slice(2)) > parseFloat(b.orderID.slice(2)) ? 1 : -1,
            )
            _this.orders = jsonOrders.reverse()

            var orderStoreCollection = {
              ordersData: _this.orders,
              newOrders: newOrders,
              totalOrders: _this.orders.length,
              uniqueCustomers: uniqueCustomers,
              totalRev: totalRevenue,
            }
            _this.$store.commit('setOrders', orderStoreCollection)
            _this.updateInfoTiles(newOrders, _this.orders.length, uniqueCustomers, totalRevenue)
            _this.loading = false
          })
          .catch(err => {
            alert(err.message)
          })
      } else {
        this.orders = this.Orders.ordersData
        this.updateInfoTiles(this.Orders.newOrders, this.orders.length, this.Orders.uniqueCustomers, this.Orders.totalRev)
        _this.loading = false
      }
    },

    getToday: function () {
      var today = new Date()
      var dd = String(today.getDate())
      var mm = String(today.getMonth() + 1)
      var yyyy = today.getFullYear()
      return dd + '/' + mm + '/' + yyyy
    },

    updateInfoTiles: function (newOrders, totalOrders, uniqueCustomers, totalRev) {
      this.infoTiles[0].value = newOrders
      this.infoTiles[1].value = totalOrders
      this.infoTiles[2].value = uniqueCustomers
      this.infoTiles[3].value = '€' + totalRev.toFixed(2)
    },
  },

  mounted () {
    this.objectName = this.$options.name
    this.getOrderData(null)
  },
}
</script>
